import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false,
  userLoginInfo: {},
};

export const loginSlice = createSlice({
  name: "loginStatus",
  initialState,
  reducers: {
    loginStatus: (state, action) => {
      state.value = action.payload;
    },
    loginUserInfo: (state, action) => {
      state.userLoginInfo = action.payload;
    },
  },
});

export const { loginStatus, loginUserInfo } = loginSlice.actions;

export default loginSlice.reducer;
