import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subTotal: 0,
  grandTotal: 0,
  shipping: 0,
};

export const amountSlice = createSlice({
  name: "Total Amount",
  initialState,
  reducers: {
    subTotalSlice: (state, action) => {
      state.subTotal = action.payload;
    },
    grandTotalSlice: (state, action) => {
      state.grandTotal = action.payload;
    },
    shippingSlice: (state, action) => {
      state.shipping = action.payload;
    },
  },
});

export const { subTotalSlice, grandTotalSlice, shippingSlice } =
  amountSlice.actions;

export default amountSlice.reducer;
