import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

export const allStoreProducts = createSlice({
  name: "All Products",
  initialState,
  reducers: {
    storeProducts: (state, action) => {
      state.products = action.payload;
    },
  },
});

export const { storeProducts } = allStoreProducts.actions;

export default allStoreProducts.reducer;
