import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../slice/Login/loginSlice";
import cartItemsReducer from "../slice/CartItems/cartItemsSlice";
import addressDataReducer from "../slice/AddressSlice/editAddressSlice";
import amountDataReducer from "../slice/TotalAmount/amountSlice";
import allProductsReducer from "../slice/Products/productSlice";
import searchProdReducer from "../slice/ProductSearch/searchSlice";
import emailReducer from "../slice/Email/emailSlice";

export const store = configureStore({
  reducer: {
    loginStatus: loginReducer,
    cartCount: cartItemsReducer,
    editAddressData: addressDataReducer,
    totalAmount: amountDataReducer,
    productStore: allProductsReducer,
    searchValues: searchProdReducer,
    emailValue: emailReducer,
  },
});
