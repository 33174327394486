import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailVal: "",
};

export const emailSlice = createSlice({
  name: "Email",
  initialState,
  reducers: {
    storeEmail: (state, action) => {
      state.emailVal = action.payload;
    },
  },
});

export const { storeEmail } = emailSlice.actions;

export default emailSlice.reducer;
