const apiStatus = (data) => {
  if (data) {
    if (data.status) {
      return data;
    }
  } else {
    alert("Something went wrong. Please check your internet connection.");
  }
};

export default apiStatus;
