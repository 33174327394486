import React, { useEffect } from "react";
import Routes from "./routes/routes";
import "../node_modules/bootstrap-4-react/dist/bootstrap-4-react";
// Helper
import checkInternetConnection from "./helper/checkInternetConnection";
// Services
import { viewCartItems, addToCartApi } from "./services/Cart";
// React-redux
import { useDispatch, useSelector } from "react-redux";
// Slice
import {
  cartItemsCount,
  cartItems,
  updateCart,
  cartLoaded,
} from "./slice/CartItems/cartItemsSlice";

function App() {
  const cardItemsUpdate = useSelector((val) => val.cartCount.updateCartItems);
  console.log("cardItemsUpdate", cardItemsUpdate);
  const dispatch = useDispatch();
  // View-All-cart_items-start
  const viewCartAllItemsFun = async () => {
    dispatch(cartLoaded(true));
    const userInfo = JSON.parse(localStorage.getItem("LOGIN_INFO"));
    if (userInfo) {
      const posted = {
        email: userInfo.EmailAddress,
      };
      await viewCartItems(posted).then((data) => {
        const verifyData = checkInternetConnection(data);
        console.log("verifyData from APP", verifyData);
        if (verifyData.status === 200) {
          dispatch(cartItemsCount(verifyData.data.length ?? 0));
          dispatch(cartItems(verifyData.data ?? []));
        }
      });
    } else {
      const locallyCartView = JSON.parse(
        localStorage.getItem("ADD_TO_CARD_NO_LOGIN")
      );
      console.log("Product View from Local Storage", locallyCartView);
      dispatch(cartItemsCount(locallyCartView?.length || 0));
      dispatch(cartItems(locallyCartView || []));
    }

    dispatch(cartLoaded(false));
  };
  // View-All-cart_items-end
  // Add-to-Cart-API-START
  const addToCartApiCall = async (
    email,
    productid,
    stockid,
    qty,
    prodLength,
    prodIndex
  ) => {
    const params = {
      email,
      productid,
      stockid,
      qty,
    };
    console.log("API Params", params);
    await addToCartApi(params).then((data) => {
      const verifyData = checkInternetConnection(data);
      if (verifyData.status === 200) {
        if (prodLength - 1 === prodIndex) {
          localStorage.removeItem("ADD_TO_CARD_NO_LOGIN");
          viewCartAllItemsFun();
        }
      }
    });
  };
  // Add-to-cart-APi-end

  useEffect(() => {
    viewCartAllItemsFun();
  }, []);

  if (cardItemsUpdate) {
    dispatch(updateCart(false));
    const userInfo = JSON.parse(localStorage.getItem("LOGIN_INFO"));
    const noLoginAddToCart = JSON.parse(
      localStorage.getItem("ADD_TO_CARD_NO_LOGIN")
    );
    if (userInfo && noLoginAddToCart) {
      for (let index = 0; index < noLoginAddToCart.length; index++) {
        addToCartApiCall(
          userInfo.EmailAddress,
          noLoginAddToCart[index]["ProductID"],
          noLoginAddToCart[index]["StockID"],
          noLoginAddToCart[index]["Quantity"],
          noLoginAddToCart.length,
          index
        );
      }
    } else {
      viewCartAllItemsFun();
    }
  }

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
