import React, { Suspense, lazy } from "react";
// ----3rd-party---- //
import { Routes, Route } from "react-router-dom";
// ----Component-for-scroll-to-top---- //
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
//route url
import config from "../config/config.json";
// ----pages---- //
const Login = lazy(() => import("../pages/Login"));
const Register = lazy(() => import("../pages/Register"));
const HomePage = lazy(() => import("../pages/HomePage"));
const Cart = lazy(() => import("../pages/Cart"));
const EmptyCart = lazy(() => import("../pages/EmptyCart"));
const Information = lazy(() => import("../pages/Information"));
const Payment = lazy(() => import("../pages/Payment"));
const Tracking = lazy(() => import("../pages/Tracking"));
const ProductDetails = lazy(() => import("../pages/ProductDetails"));
const ForgetPassword = lazy(() => import("../pages/ForgetPassword"));
const SendEmail = lazy(() => import("../pages/SendEmail"));
const Profile = lazy(() => import("../pages/Profile"));
const TrackOrder = lazy(() => import("../pages/TrackOrder"));
const OrderHistory = lazy(() => import("../pages/OrderHistory"));
const SearchProducts = lazy(() => import("../pages/SearchProducts"));
const AccountInfo = lazy(() => import("../pages/AccountInfo"));
const Address = lazy(() => import("../pages/Address"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const TermsOfUser = lazy(() => import("../pages/TermsOfUser"));
const TermsOfSale = lazy(() => import("../pages/TermsOfSale"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const ReturnUrl = lazy(() => import("../pages/ReturnUrl"));
const AboutUs = lazy(() => import("../pages/AboutUs"));

// Loader
import { ClipLoader } from "react-spinners";

const url = config.routeUrl;

const spinnerStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export default function routes() {
  return (
    <>
      <Suspense
        fallback={<ClipLoader color={"#e10a02"} size={60} css={spinnerStyle} />}
      >
        <Routes>
          <Route path={url} element={<HomePage />} />
          <Route path={url + "/login"} element={<Login />} />
          <Route path={url + "sign-up"} element={<Register />} />
          <Route path={url + "search"} element={<SearchProducts />} />
          <Route path={url + "cart"} element={<Cart />} />
          <Route path={url + "empty-cart"} element={<EmptyCart />} />
          <Route path={url + "information"} element={<Information />} />
          <Route path={url + "payment"} element={<Payment />} />
          <Route path={url + "tracking"} element={<Tracking />} />
          <Route path={url + "product-details"} element={<ProductDetails />} />
          <Route path={url + "forget-password"} element={<ForgetPassword />} />
          <Route path={url + "check-email"} element={<SendEmail />} />
          <Route path={url + "profile"} element={<Profile />} />
          <Route path={url + "track-order"} element={<TrackOrder />} />
          <Route path={url + "order-history"} element={<OrderHistory />} />
          <Route path={url + "account-info"} element={<AccountInfo />} />
          <Route path={url + "address-info"} element={<Address />} />
          <Route path={url + "privacy-policy"} element={<PrivacyPolicy />} />
          <Route path={url + "terms-conditions"} element={<TermsOfUser />} />
          <Route path={url + "terms-sale"} element={<TermsOfSale />} />
          <Route path={url + "reset-password"} element={<ResetPassword />} />
          <Route path={url + "about-us"} element={<AboutUs />} />
          <Route path={url + "return-url"} element={<ReturnUrl />} />
          <Route path="/*" element={<div>Page not Found</div>} />
        </Routes>
        <ScrollToTop />
      </Suspense>
    </>
  );
}
