import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editAddress: {},
};

export const addressDataSlice = createSlice({
  name: "addressData",
  initialState,
  reducers: {
    getAddressData: (state, action) => {
      state.editAddress = action.payload;
    },
  },
});

export const { getAddressData } = addressDataSlice.actions;
export default addressDataSlice.reducer;
