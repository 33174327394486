import axios from "../axios/axiosConfig";
// endPoint
const endPoint = "/Cart/Add";
const viewEndPoint = "/Cart/View";
const deleteEndPoint = "/Cart/Delete";
const editEndPoint = "/Cart/Edit";
// add-cart-API-start
export const addToCartApi = async (data) => {
  try {
    const response = await axios.post(endPoint, data);
    return response;
  } catch (error) {
    console.log("ERROR On add-cart API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// add-cart-API-End
// View-cart-Api-start
export const viewCartItems = async (data) => {
  try {
    const response = await axios.post(viewEndPoint, data);
    return response;
  } catch (error) {
    console.log("ERROR On /Cart/View API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// View-cart-Api-end
// Delete-cart-Api-start
export const deleteCartItem = async (data) => {
  try {
    const response = await axios.post(deleteEndPoint, data);
    return response;
  } catch (error) {
    console.log("ERROR On /Cart/Delete API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Delete-cart-Api-end
// Edit-cart-Api-start
export const editCartItem = async (data) => {
  try {
    const response = await axios.post(editEndPoint, data);
    return response;
  } catch (error) {
    console.log("ERROR On /Cart/Edit API:", error);
    if (error.response) {
      return error.response;
    }
  }
};
// Edit-cart-Api-end
